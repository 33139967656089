export const convertCategoriesTree = (inputArray: any): any => {
  const map = new Map();
  const result: any = [];

  inputArray.forEach((item: any) => {
    // map.set(item.id, { ...item, label: item?.name, children: [] });
    map.set(item.id, { 
      ...item, 
      label: item?.name, 
      children: item.sub_catalogs ? convertCategoriesTree(item.sub_catalogs) : [] 
    });
  });

  inputArray.forEach((item: any) => {
    const parent = map.get(item.parent_id);
    if (parent) {
      parent.children.push(map.get(item.id));
    } else {
      result.push(map.get(item.id));
    }
  });
  return result;
};

function findCategoryById(data: any, targetId: string): any {
  if (data.id === targetId) {
    return data;
  }

  if (data.children && data.children.length > 0) {
    for (const child of data.children) {
      const result = findCategoryById(child, targetId);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export function findCategory(data: any[], targetId: string) {
  for (const item of data) {
    if (findCategoryById(item, targetId)) {
      return findCategoryById(item, targetId);
    }
  }
}
