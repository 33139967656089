import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeVi from '@angular/common/locales/vi';
import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopControllerService, ShopManagementControllerService } from '@soctrip/angular-shop-service';
import * as CryptoJS from 'crypto-js';
import { MessageService } from 'primeng/api';
import { Observable, catchError, forkJoin, of, switchMap } from 'rxjs';

import { RoleEnum } from 'src/app/core/enum/role.enum';
import { User } from 'src/app/core/models/interfaces/user';
import { AppService } from 'src/app/core/services/app.service';
import { BillingService } from 'src/app/core/services/billing.service';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { RoleService } from 'src/app/core/services/role.service';
import { Toast } from 'src/app/core/utils/toast.util';
import { environment } from 'src/environments/environment';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  providers: [MessageService, Toast],
})
export class MainComponent implements OnInit {
  smallScreen = 1280;
  isSmallScreen: boolean = false;

  user: User;
  stateView: string = STATE_RENDER_APP.INIT;

  fetched = false;
  myShopId = '';
  iframeUrl: SafeResourceUrl;
  currency: string = '';
  adminToken: string = '';
  adminId = '';
  permission = '';

  constructor(
    private route: ActivatedRoute,
    private shopService: ShopControllerService,
    private router: Router,
    private shopManagementService: ShopManagementControllerService,
    private roleService: RoleService,
    private sanitizer: DomSanitizer,
    private permissionService: PermissionService,
    private translator: CustomTranslateService,
    private httpClient: HttpClient,
    private billingService: BillingService,
    private messageService: MessageService,
    private appService: AppService,
  ) {}

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParamMap;
    const adminToken = queryParams.get('adminToken') || '';
    const permissionParam = queryParams.get('permission') || '';
    this.adminId = queryParams.get('userId') || '';

    if (permissionParam) {
      localStorage.setItem('permission', permissionParam);
    }
    this.permission = localStorage.getItem('permission') || '';
    if (!['admin', 'user'].includes(this.permission)) {
      this.onBackLogin();
    }

    if (adminToken) {
      try {
        const bytes = CryptoJS.AES.decrypt(adminToken.toString(), 'token');
        const token = bytes.toString(CryptoJS.enc.Utf8);
        this.adminToken = token.slice(0, -5);
      } catch (error) {
        this.onBackLogin();
      }
    }

    if ((this.adminToken && this.adminId) || this.permission === 'admin') {
      if (this.adminToken) {
        localStorage.setItem('accessToken', this.adminToken);
      }

      this.onCheckShop();
      this.onClearParams(['userId', 'adminToken', 'shopId', 'permission']);
    }

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.IFRAME_CONNECT_URL);
    this.checkSmallScreen();
  }

  onClearParams(params: string[]) {
    const snapshot = this.route.snapshot.queryParams;
    const queryParams = { ...snapshot };
    params.forEach((param) => {
      delete queryParams?.[param];
      delete queryParams?.[param];
    });

    this.router.navigate([], { queryParams: queryParams, relativeTo: this.router.routerState.root.firstChild });
  }

  onBackLogin() {
    window.location.href = `${environment.FE_URL_SALE}`;
  }

  @HostListener('window:message', ['$event'])
  handleReceiveMessage = (e: MessageEvent) => {
    if (!(this.permission === 'user')) return;
    if (e?.data?.type === 'authorized') {
      const accessTokenData = e.data?.token?.accessToken;
      const profileData = JSON.parse(e.data?.profile);
      if (accessTokenData && profileData) {
        localStorage.removeItem('liveToken');
        localStorage.setItem('accessToken', accessTokenData);
        localStorage.setItem('auth', JSON.stringify(profileData));

        this.user = profileData;
        this.onCheckShop();
      } else {
        this.onBackLogin();
      }
    }
  };

  @HostListener('window:resize', ['$event'])
  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  onCheckShop() {
    const paramsShopIdUrl = this.route.snapshot.paramMap.get('shopId');
    const paramsShopId = paramsShopIdUrl && paramsShopIdUrl !== 'undefined' ? paramsShopIdUrl : '';

    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    const userId = this.adminId || this.user?.id || auth.id;

    let sources: Observable<any>[] = [
      this.httpClient.get(`${environment.BE_URL}user/users/${userId}?private_privacy=false`),
    ];

    if (!paramsShopId) {
      sources.push(this.shopService.shopsInfoGet());
    } else {
      sources.push(this.shopService.shopsIdInfoGet(paramsShopId));
    }

    forkJoin(sources)
      .pipe(
        catchError(() => {
          window.location.href = `${environment.FE_URL_SALE}`;
          return of();
        }),
        switchMap((results) => {
          const [userRes, shopRes] = results;
          if (!userRes?.data || !shopRes?.data) {
            window.location.href = `${environment.FE_URL_SALE}`;
          }

          const language = userRes?.data?.language == 'VI' ? 'vi' : 'en';
          registerLocaleData(localeVi, 'vi');

          this.user = userRes?.data;
          this.currency = shopRes?.data?.currency?.toUpperCase();
          this.translator.setLanguage(language);
          this.myShopId = shopRes?.data?.id;

          localStorage.setItem('lang', language);
          localStorage.setItem('auth', JSON.stringify(this.user));

          this.appService.setShopData(shopRes?.data);
          this.appService.setIsSuspension(!!shopRes?.data?.is_suspension_mode);

          return forkJoin([
            this.shopManagementService.shopManagementObjectIdMyUsersRoleGet(this.myShopId),
            this.permissionService.getUserPermissions(userId),
            this.billingService.getConversionCurrency(this.currency || 'USD'),
            this.shopService.shopsRolesIdGet(this.myShopId),
          ]);
        }),
      )
      .subscribe({
        next: (results) => {
          const [roleRes, permissionRes, currencyRes, shopRolesRes] = results;
          if (this.currency != 'USD' && !currencyRes.data) {
            this.messageService.add({
              severity: 'error',
              detail: this.translator.transform('section-shop-information.currency-failed'),
              life: 15000,
            });
          }
          const expiry = new Date().getTime() + 1 * 60 * 60 * 1000;
          const rate = currencyRes.data || 1;
          localStorage.setItem(
            'currency-conversation-data',
            JSON.stringify({
              currency: this.currency,
              rate: rate,
              expiry: expiry,
              hash: this.currency + rate + expiry,
            }),
          );

          const isAdminShop = permissionRes.data.includes(RoleEnum.SUPER_ADMIN_SHOP);
          const role =
            roleRes.data == RoleEnum.E_OWNER
              ? RoleEnum.E_OWNER
              : isAdminShop
              ? RoleEnum.SUPER_ADMIN_SHOP
              : roleRes.data;
          this.roleService.setRoles([role]);

          const codes = shopRolesRes.data.map((item: any) => item.code);
          if (codes.includes(RoleEnum.E_TICKET_MANAGEMENT)) {
            this.roleService.setIsETicket(true);
          }

          this.onClearParams(['shopId', 'permission']);
          this.stateView = STATE_RENDER_APP.APP;
          this.fetched = true;
        },
        error: () => {
          this.fetched = true;
        },
      });
  }

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';
}
