<div ngModelGroup="general_info" class="bg-white rounded-lg border px-4 py-6">
  <div *ngIf="productData?.moderate_status === 'BLOCKED' && isViewMode && !isIframe" class="pb-4">
    <p class="text-error-500 font-semibold">Reason: {{ productData?.moderate_note }}</p>
  </div>
  <h3 class="mb-6 font-semibold">
    {{ managementPrefix + "general-information" | translate | sentenceCase }}
  </h3>

  <!-- OTHER INFO -->
  <div *ngIf="isViewMode" class="flex flex-col gap-4 text-sm mb-6">
    <div>
      <label class="font-medium" htmlFor="description"> Shop name: </label>
      <span>{{ productData.shop?.name || "--" }}</span>
    </div>

    <div>
      <label class="font-medium" htmlFor="description"> Shop code: </label>
      <span>{{ productData.s_sku?.split("_")?.[0] || "--" }}</span>
    </div>

    <div>
      <label class="font-medium" htmlFor="description"> Product code: </label>
      <span>{{ productData.s_sku || "--" }}</span>
    </div>
    <div class="flex gap-2">
      <label class="font-medium" htmlFor="description"> Created/edited by: </label>

      <div class="flex flex-wrap gap-3">
        <div class="flex align-items-center">
          <ng-container
            [ngTemplateOutlet]="CheckBox"
            [ngTemplateOutletContext]="{ check: checkCreatedEditedBy() }"
          ></ng-container>
          <label class="ml-2"> Seller </label>
        </div>

        <div class="flex align-items-center">
          <ng-container
            [ngTemplateOutlet]="CheckBox"
            [ngTemplateOutletContext]="{ check: !checkCreatedEditedBy() }"
          ></ng-container>
          <label class="ml-2"> Admin </label>
        </div>
      </div>
    </div>
  </div>

  <div class="flex">
    <!-- LEFT SECTION 1-->
    <div class="w-1/2 mr-4">
      <app-product-upload-images
        [isSubmitted]="isSubmitted"
        [(attachments)]="productForm.attachments"
        [attachmentsDisplay]="attachments"
        (attachmentsDisplayChange)="onAttachmentsChange($event)"
        [(avatarIndex)]="productForm.avatarIndex"
        [isViewMode]="isViewMode"
      ></app-product-upload-images>
    </div>
    <!-- RIGHT SECTION 1-->
    <div class="flex-1 flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <label class="text-sm text-gray-700 font-medium" htmlFor="name">
          {{ managementPrefix + "product-name" | translate | sentenceCase }}
          <span class="text-orange-dark-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
        </label>
        <input
          #productName="ngModel"
          [(ngModel)]="productForm.name"
          [placeholder]="managementPrefix + 'enter-product-name' | translate | sentenceCase"
          class="h-10"
          name="product_name"
          pInputText
          required
          [maxlength]="120"
          [minlength]="10"
          [readOnly]="isViewMode"
        />
        <div class="flex justify-between">
          <span>
            <small *ngIf="productName.errors?.['minlength']" class="flex text-error-500 text-xs">{{
              managementPrefix + "validate.name-min-length" | translate
            }}</small>
          </span>
          <small
            class="flex text-gray-500 text-xs justify-end {{
              productForm.name.length == 120 ? 'error-highlight-animation' : ''
            }}"
            id="name-help"
          >
            {{ productForm.name.length }}/120
            {{ "common.characters" | translate }}
          </small>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <label class="text-sm font-medium" htmlFor="category">
          {{ "common.category" | translate | sentenceCase }}
          <span class="text-orange-dark-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
        </label>

        <!-- CATEGORY -->
        <div class="grid gap-y-1.5">
          <div class="relative">
            <div
              (click)="isSelectionDropped = !isSelectionDropped"
              class="flex justify-between items-center px-3 py-2 hover:cursor-pointer hover:shadow-md"
              [ngClass]="[
                isSubmitted && breadCrumbNames.length === 0
                  ? 'border border-error-500 rounded-lg'
                  : 'border border-gray-300 rounded-lg'
              ]"
            >
              <span>
                <span *ngIf="!breadCrumbNames.length" class="text-sm text-gray-500/80">
                  {{ categoryPrefix + "category-type" | translate | sentenceCase }}
                </span>
                <span *ngIf="breadCrumbNames.length" class="text-sm font-normal">
                  <span>{{ breadCrumbNames.join(" > ") }}</span>
                </span>
              </span>
              <span class="flex gap-3 items-center">
                <i class="pi pi-angle-down"></i>
              </span>
            </div>

            <div
              *ngIf="isSelectionDropped"
              class="flex text-sm absolute z-[99999] top-full h-[25rem] w-full overflow-x-auto overflow-y-clip border bg-white border-gray-200 shadow-lg"
            >
              <div *ngFor="let categories of categoriesGlobalList" class="overflow-auto border-r border-gray-200 w-1/5">
                <div
                  *ngFor="let category of categories"
                  (mouseover)="hoverCategory($event, category)"
                  (click)="selectCategory($event, category)"
                  class="flex justify-between items-center p-2 gap-2 hover:bg-primary-50 hover:text-primary-700 hover:cursor-pointer"
                >
                  <span>{{ category.name }}</span>
                  <i *ngIf="category.sub_catalogs" class="pi pi-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <input *ngIf="isViewMode" readonly type="text" pInputText [value]="productForm.category?.name_final || ''" />
      </div>
      <!-- Hashtags -->
      <div class="flex flex-col gap-2">
        <label class="text-sm font-medium" htmlFor="category">
          {{ "common.tags" | translate | sentenceCase }}
        </label>
        <div class="card p-fluid" [ariaReadOnly]="isViewMode">
          <p-chips
            (onAdd)="onAddChip($event)"
            [(ngModel)]="productForm.hashtags"
            [inputStyle]="{ 'box-shadow': 'unset' }"
            [max]="20"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="('common.hint' | translate | sentenceCase) + ': a, b c'"
            separator=","
            [disabled]="isViewMode"
            [addOnBlur]="true"
          >
          </p-chips>
        </div>
        <small class="flex text-gray-500 text-xs justify-end">
          {{ productForm.hashtags.length }}/20
          {{ "common.hashtags" | translate | sentenceCase }}
        </small>
      </div>
    </div>
  </div>
</div>

<ng-template #CheckBox let-check="check">
  <div
    class="flex justify-center items-center h-5 w-5 rounded-full p-[2px] border"
    [ngClass]="{ 'border-blue-500': check, 'border-gray-300': !check }"
  >
    <div class="h-[15px] w-[15px] rounded-full" [ngClass]="{ 'bg-blue-500': check }"></div>
  </div>
</ng-template>
