import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './features/main/main.component';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';
import { IframeApprovalProductComponent } from './features/products/pages/iframe-approval-product/iframe-approval-product.component';
import { WelcomeComponent } from './shared/components/welcome/welcome.component';
import { OrderShipMentComponent } from './features/orders/components/order-shipment-print/order-shipment-print.component';

const routes: Routes = [
  {
    path: 'shops/:shopId',
    component: MainComponent,
    loadChildren: () => import('./features/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  { path: 'shipment/:code', component: OrderShipMentComponent },
  {
    path: 'share-token',
    loadChildren: () => import('./features/share-token/share-token.module').then((m) => m.ShareTokenModule),
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'approval-request/product/:productId',
    component: IframeApprovalProductComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
